import EliteSoftwarehub from '../assets/elitegen.png';
const Loader = () => (
	<div className='loader'>
		<div className='loader-img flex'>
			<img src={EliteSoftwarehub} alt='EliteSoftwarehub Logo' />
		</div>
	</div>
);

export default Loader;
